<template>
<div>
    <v-app>
      <!-- <v-container> -->
        <div class="container">
      <v-row>
        <v-col lg="3">
          <v-card width="400" height="663">
            <v-card-text>
              <div class="d-flex align-items-center mt-2">
                <h5 class="text-uppercase">Kegiatan hari ini</h5>
                <!-- <select class="form-control">
                  <option>Oktober</option>
                  <option>November</option>
                  <option>Desember</option>
                  <option>Januari 2021</option>
                  <option>Februari 2021</option>
                </select> -->
              </div>
              <perfect-scrollbar
                class="aside-menu scroll"
                style="max-height: 115vh; position: relative"
              >
                <v-timeline align-top dense>
                  <template v-for="today in classwork_today">
                  <v-timeline-item color="green" small :key="today.id">
                    <div>
                      <div class="font-weight-normal">
                        <strong v-if="today.agenda">
                          {{setDate(today.start_date, today.end_date)}}
                        </strong>
                        <strong v-else>{{setDate(today.start_date, today.due_date)}}</strong>
                      </div>
                      <div v-if="today.agenda">{{today.agenda}}</div>
                      <div v-else>{{today.title}}</div>
                    </div>
                  </v-timeline-item>
                  </template>
                </v-timeline>
              </perfect-scrollbar>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-sheet class="px-6 pt-5 sheet-filter">
            <v-row class="align-items-center">
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Hari Ini
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                  >
                    <span>{{classroom_actv}}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="resetFilter()">
                    <v-list-item-title>Semua Kelas</v-list-item-title>
                  </v-list-item>
                  <template v-for="classroom in classrooms">
                  <v-list-item @click="filterByClassroom(classroom.id, classroom.name)" :key="classroom.id">
                    <v-list-item-title>{{classroom.name}}</v-list-item-title>
                  </v-list-item>
                  </template>
                </v-list>
              </v-menu>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Hari</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Minggu</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Bulan</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 Hari</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <!-- <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn> -->
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <!-- <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn> -->
                </v-toolbar>
                <v-card-text>
                  <table>
                    <tr>
                      <th class="h6">Kelas</th>
                      <th class="h6">:</th>
                      <td class="h6 font-weight-normal pl-1">{{selectedEvent.classroom_name}}</td>
                    </tr>
                    <tr>
                      <th class="h6">Dosen</th>
                      <th class="h6">:</th>
                      <td class="h6 font-weight-normal pl-1">{{selectedEvent.teacher_name}}</td>
                    </tr>
                    <tr>
                      <th class="h6">Waktu</th>
                      <th class="h6">:</th>
                      <td><span class="h6 font-weight-normal pl-1">{{convertDate(selectedEvent.start)}}</span> -
                          <span class="h6 font-weight-normal">{{convertDate(selectedEvent.end)}}</span>
                      </td>
                    </tr>
                  </table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
        </div>
      <!-- </v-container> -->
    </v-app>
</div>
</template>

<script>
// @ is an alias to /src
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Bulan",
        week: "Minggu",
        day: "Hari",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      // events: [
      //   {
      //     name: "test",
      //     start: "2021-06-25 10:00:00",
      //     end: "2021-06-26 11:30:00",
      //     color: "cyan",
      //   },
      //   {
      //     name: "test",
      //     start: "2021-06-27 07:00:00",
      //     end: "2021-06-28 07:25:00",
      //     color: "green",
      //   },
      //   {
      //     name: "test",
      //     start: "2021-06-28 08:00:00",
      //     end: "2021-06-28 08:15:00",
      //     color: "red",
      //   },
      // ],
      events: [],
      events_form:{
        name: "",
        start: "",
        end: "",
        color: "green",
        classroom: [],
        classroom_name: "",
        teacher_name: ""
      },
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      // 
      assignment_classworks: [],
      classwork_quizzes: [],
      classwork_schedules: [],
      classrooms: [],
      classroom_actv: "Semua Kelas",
      // today
      classwork_today: [],
      // filter
      filter:{
        classroom_id: ""
      }
    };
  },
  methods: {
    async getSchedules() {
      this.events = []

      let response = await module.getSchedules(
        `api/calendars/classworks/schedules/users/${getUser().id}`,
        `?classroomId=${this.filter.classroom_id}`
      );
      this.assignment_classworks = response.data.assignment_classworks
      for(let a = 0; a < this.assignment_classworks.length; a++){
        this.events_form.name=this.assignment_classworks[a].title
        this.events_form.start=this.assignment_classworks[a].start_date
        this.events_form.end=this.assignment_classworks[a].due_date

        if(this.assignment_classworks[a].classroom_index){
          this.events_form.classroom =this.assignment_classworks[a].classroom[this.assignment_classworks[a].classroom_index]
          this.events_form.classroom_name =this.assignment_classworks[a].classroom[this.assignment_classworks[a].classroom_index].name
          this.events_form.teacher_name =this.assignment_classworks[a].classroom[this.assignment_classworks[a].classroom_index].created_by_name
        }

        let clone = { ...this.events_form }
        this.events.push(clone)
      }

      this.classwork_quizzes = response.data.classwork_quizzes
      for(let b = 0; b < this.classwork_quizzes.length; b++){

        this.events_form.name=this.classwork_quizzes[b].title
        this.events_form.start=this.classwork_quizzes[b].start_date
        this.events_form.end=this.classwork_quizzes[b].due_date
        this.events_form.color="cyan"

        if(this.classwork_quizzes[b].classroom_index){
          this.events_form.classroom =this.classwork_quizzes[b].classroom[this.classwork_quizzes[b].classroom_index]
          this.events_form.classroom_name =this.classwork_quizzes[b].classroom[this.classwork_quizzes[b].classroom_index].name
          this.events_form.teacher_name =this.classwork_quizzes[b].classroom[this.classwork_quizzes[b].classroom_index].created_by_name
        }

        let cloneQuiz = { ...this.events_form }
        this.events.push(cloneQuiz)
      }

      this.classwork_schedules = response.data.classwork_schedules
      // //("classwork_schedules", response.data.classwork_schedules)
      for(let c = 0; c < this.classwork_schedules.length; c++){
        this.events_form.name = this.classwork_schedules[c].agenda
        this.events_form.start = this.classwork_schedules[c].start_date
        this.events_form.end = this.classwork_schedules[c].end_date
        this.events_form.color = "blue"

        if(this.classwork_schedules[c].classroom_index){
          this.events_form.classroom =this.classwork_schedules[c].classroom[this.classwork_schedules[c].classroom_index]
          this.events_form.classroom_name =this.classwork_schedules[c].classroom[this.classwork_schedules[c].classroom_index].name
          this.events_form.teacher_name =this.classwork_schedules[c].classroom[this.classwork_schedules[c].classroom_index].created_by_name
        }

        let cloneSchedule = { ...this.events_form }
        this.events.push(cloneSchedule)
      }

    },
    async getClassrooms(){
      let response = await module.getSchedules(
        `api/classrooms/my-classrooms/${getUser().id}`,
      );
      this.classrooms = response.data.data
    },
    filterByClassroom(id, name){
      this.classroom_actv = name
      this.filter.classroom_id = id
      //("id", id)
      this.events = []
      this.getSchedules()
    },
    resetFilter(){
      this.classroom_actv = "Semua Kelas"
      this.filter.classroom_id = ""
      this.events = []
      this.getSchedules()
    },
    async getSchedulesToday() {
      this.events = []

      let today = new Date().toISOString().substr(0, 10)

      let response = await module.getSchedules(
        `api/calendars/classworks/schedules/users/${getUser().id}`,
        `?today=${today}`
      );

      for(let a = 0; a < response.data.assignment_classworks.length; a++){
        this.classwork_today.push(response.data.assignment_classworks[a])
      }

      for(let b = 0; b < response.data.classwork_quizzes.length; b++){
        this.classwork_today.push(response.data.classwork_quizzes[b])
      }

      for(let c = 0; c < response.data.classwork_schedules.length; c++){
        this.classwork_today.push(response.data.classwork_schedules[c])
      }

    },
    convertDate(date){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(date).toLocaleDateString("id-ID", options)
      return localdata
    },
    setEndDate(date){
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      let localdata = new Date(date).toLocaleDateString("id-ID", options)
      return localdata.split(" ")[1] + " " + localdata.split(" ")[2] + " " + localdata.split(" ")[3]
    },
    setDate(start_date, end_date){
      let start = new Date(start_date).toDateString()
      let end = new Date(end_date).toDateString()
      if(start == end){
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let localdata = new Date(start_date).toLocaleDateString("id-ID", options)
        return localdata.split(" ")[1] + " " + localdata.split(" ")[2] + " " + localdata.split(" ")[3]
      }else{
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let localStartDate = new Date(start_date).toLocaleDateString("id-ID", options)
        let localEndDate = new Date(end_date).toLocaleDateString("id-ID", options)
        return localStartDate.split(" ")[1] + " " + localStartDate.split(" ")[2] + " - " +
               localEndDate.split(" ")[1] + " " + localEndDate.split(" ")[2] + " " + localEndDate.split(" ")[3]
      }

      // return new Date(date).toLocaleDateString("id-ID", options)
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    // updateRange({ start, end }) {
    //   const events = [];

    //   const min = new Date(`${start.date}T00:00:00`);
    //   const max = new Date(`${end.date}T23:59:59`);
    //   const days = (max.getTime() - min.getTime()) / 86400000;
    //   const eventCount = this.rnd(days, days + 20);

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0;
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime());
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000));
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
    //     const second = new Date(first.getTime() + secondTimestamp);

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: this.formatDate(first, !allDay),
    //       end: this.formatDate(second, !allDay),
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //     });
    //   }

    //   this.start = start;
    //   this.end = end;
    //   this.events = events;
    // },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
    formatDate(a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${
            a.getMonth() + 1
          }-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Calendar" }]);
    this.$refs.calendar.checkChange();
    this.getSchedules();
    this.getSchedulesToday()
    this.getClassrooms();
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day + this.nth(start.day);
      const endDay = end.day + this.nth(end.day);

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`;
        case "day":
          return `${startMonth} ${startDay} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
};
</script>

<style scoped>

.sheet-filter{
  height: 64px;
}

/* mobile */
@media (max-width: 767.98px) { 
  .sheet-filter{
    height: 90px;
  }
}
</style>